<template>
	<div class="open-account-bank">
		<van-form @submit="nextStep()" :label-width="100">

			<h4 class="h4_title">海外银行账户信息</h4>

			<van-field 
				v-model="formItem.country" 
				label="国家" 
				placeholder="请输入"
				:rules="rules.country"
				required
			/>
			
			<van-field 
				v-model="formItem.bank" 
				label="银行" 
				placeholder="请输入"
				:rules="rules.bank"
				required
			/>

			<van-field 
				v-model="formItem.bank_address" 
				label="银行地址" 
				placeholder="请输入"
				:rules="rules.bank_address"
				required
			/>
			<van-field 
				v-model="formItem.account_name" 
				label="账户名" 
				placeholder="请输入"
				:rules="rules.account_name"
				required
			/>
			<van-field 
				v-model="formItem.account_no" 
				label="银行卡号" 
				placeholder="请输入"
				:rules="rules.account_no"
				required
			/>
			<van-field
				v-model="formItem.account_address" 
				label="账户地址" 
				placeholder="请输入"
				:rules="rules.account_address"
				required
			/>

			<van-field
				v-model="formItem.swift_code"
				label="SWIFT CODE"
				placeholder="请输入"
				:rules="rules.swift_code"
				required
			/>
			
			<div class="common_block" style="color: orange;font-size: 16px;" v-if="status == 1"><van-icon name="clock-o" /> 资料正在审核中……</div>
			<div class="common_block" style="color: green;font-size: 16px;" v-if="status == 2"><van-icon name="passed" /> 审核通过</div>
			<div class="common_block" style="color: red;font-size: 16px;" v-if="status == 3"><van-icon name="close" /> 审核不通过，原因：{{ remarks }}</div>

			<div class="common_block" v-if="status == 0 || status == 3">
				<van-button type="warning" block native-type="submit">提交修改</van-button>
			</div>

			<div class="common_block" v-if="status == 2">
				<van-button type="primary" block native-type="submit">重新认证</van-button>
			</div>
		</van-form>
	
	</div>
</template>

<script>
	export default {
		name: 'account_hkbank',
		data() {
			return {
				status: 10,
				remarks: "",

				formItem: {
					country: "",
					bank: "",
					bank_address: "",
					account_name: "",
					account_no: "",
					account_address: "",
					swift_code: "",
				},

				rules: {
					country: [
						{
							required: true,
							message: '请填写国家',
							trigger: 'onBlur'
						}
					],
					bank: [
						{
							required: true,
							message: '请填写银行',
							trigger: 'onBlur'
						}
					],
					bank_address: [
						{
							required: true,
							message: '请填写银行地址',
							trigger: 'onBlur'
						}
					],
					account_name: [
						{
							required: true,
							message: '请填写账户名',
							trigger: 'onBlur'
						}
					],
					account_no: [
						{
							required: true,
							message: '请填写银行卡号',
							trigger: 'onBlur'
						}
					],
					account_address: [
						{
							required: true,
							message: '请填写账户地址',
							trigger: 'onBlur'
						}
					],
					swift_code: [
						{
							required: true,
							message: '请填写SWIFT CODE',
							trigger: 'onBlur'
						}
					],
				},

				token: "",
			}
		},
		mounted() {
			
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.token = this.$route.query.token
				
				this.init()
			}
		},
		methods: {
			init(){
				
				this.$axios({
					method: 'post',
					url: 'account_bank/info',
					data: {
						token: this.token
					}
				}).then ((res) => {
					if (res.success) {
							var abroad_data = res.data.abroad_data		
							
							if (abroad_data.length != 0) {
								this.formItem.country = abroad_data.country ? abroad_data.country : ""
								this.formItem.bank = abroad_data.bank ? abroad_data.bank : ""
								this.formItem.bank_address = abroad_data.bank_address ? abroad_data.bank_address : ""
								this.formItem.account_name = abroad_data.account_name ? abroad_data.account_name : ""
								this.formItem.account_no = abroad_data.account_no ? abroad_data.account_no : ""
								this.formItem.account_address = abroad_data.account_address ? abroad_data.account_address : ""
								this.formItem.swift_code = abroad_data.swift_code ? abroad_data.swift_code : ""
							}

							this.status = res.data.status
							this.remarks = res.data.remarks
					}else{
						this.$toast.fail(res.error_text)
					}
				})
			},

			nextStep(){

				this.$axios({
					method: 'post',
					url: 'account_bank/alter',
					data: {
						token: this.token,
						abroad_data: JSON.stringify(this.formItem)
					}
				}).then ((res) => {
					if (res.success) {
						this.$toast.success('提交成功，请等待审核结果')

						this.init()
					}else{
						this.$toast.fail(res.error_text)
					}
				})
				
			}
		},
	}
</script>